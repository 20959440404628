<template>
  <tr
    v-for="item in data"
    :key="item.id"
    class="border-b hover:bg-orange-100 bg-gray-100 text-grey hover:bg-gray-200"
  >
    <td class="p-3 px-5">
      <p v-if="item.name">{{ item.name }}</p>
    </td>
    <td class="p-3 px-5">
      <p v-if="item.role.name">
        {{ item.role.name }}
      </p>
    </td>
    <interviewPolicySingleItemInternalFunctionality :item="item" />

  </tr>
</template>

<script>
// import moment from "moment";

import interviewPolicySingleItemInternalFunctionality from "./interviewPolicySingleItemInternalFunctionality.vue"
export default {
  components: {
    interviewPolicySingleItemInternalFunctionality,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    link_1: {
      type: String,
    },
    link_2: {
      type: String,
    },
    myParameter_1: {
      type: String,
    },
    myParameter_2: {
      type: String,
    },
  },
  data() {
    return {
      showDropDown: false,
     
    };
  },
  methods: {
    // isAddLive(data) {
    //   return data.some((date) => moment(date.end_date) >= moment());
    // },
    debug(event) {
      console.log(event);
    },
  },
};
</script>
