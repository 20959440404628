<template>
  <div
    class="flex items-center bg-lightGrey py-7 rounded-xl text-grey bg-opacity-60 hover:bg-opacity-100 justify-center transition-all ease-in duration-200 cursor-pointer"
  >
    <span class="material-icons text-6xl"> {{ icon }} </span>
    <span class="text-lg mx-2">{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
