<template>
  <transition appear>
    <div class="mt-10 w-10/12 m-auto">
      <div class="flex gap-10 justify-items-start w-11/12 m-auto">
        <div class="flex flex-col gap-y-3">
          <router-link :to="{ name: 're-create-new-interview-policy' }">
            <horizontal-button-tile
              icon="description"
              title="New Interview Questionnaire"
            />
          </router-link>
        </div>
      </div>
      <div class="flex justify-center mt-5" v-if="getInterviewPolicies?.length">
        <!-- OPEN Vacancies TITLE -->
        <div
          v-if="!isLoading"
          class="bg-lightGrey rounded-xl w-11/12 shadow-md"
        >
          <div class="pl-4 py-4">
            <h2 class="text-grey font-bold">INTERVIEW QUESTIONNAIRES</h2>
          </div>
          <!-- <template v-if="!isLoading"> -->
          <template v-if="getInterviewPolicies">
            <table class="text-center w-full text-grey my-2">
              <dynamic-table class="text-teal" :showTitle="false" title="">
                <interview-policies-table-item
                  v-if="!isLoading"
                  :data="getInterviewPolicies"
                  :link_2="'re-manage-vacancy'"
                  :myParameter_2="'manageVacancy'"
                >
                </interview-policies-table-item>
              </dynamic-table>
            </table>
            <my-pagination
              :options="options"
              v-model="page"
              :records="interviewsData?.data?.['interview-policies']?.total"
              :per-page="10"
              @paginate="myCallback($event, null)"
            >
            </my-pagination>
          </template>
          <no-data-found v-else />
        </div>
        <template v-else>
          <table-skeleton />
        </template>
      </div>
      <div v-else class="text-center w-full text-grey my-2"><table-skeleton /></div>
    </div>
  </transition>
</template>
<script>
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import noDataFound from "@/components/ui/baseComponents/noDataFound.vue";
import tableSkeleton from "@/components/ui/baseComponents/skeletons/tableSkeleton.vue";
import dynamicTable from "@/components/ui/baseComponents/Tables/greyTable.vue";
import horizontalButtonTile from "@/components/ui/baseComponents/horizontalButtonTile.vue";
import interviewPoliciesTableItem from "@/components/ui/baseComponents/Tables/RE/interviewPoliciesTableItem.vue";

export default {
  provide() {
    return { headers: this.tableHeaders };
  },
  async created() {
    await this.fetchAllInterviewPolicies(1);
  },
  components: {
    noDataFound,
    tableSkeleton,
    dynamicTable,
    // openVacanciesTableItem,
    horizontalButtonTile,
    interviewPoliciesTableItem,
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      tableHeaders: ["Name", "Role"],
      options: {
        template: markRaw(customPaginate),
      },
      interviewsData: {},
    };
  },
  methods: {
    async fetchAllInterviewPolicies(page) {
      try {
        this.isLoading = true;
        this.interviewsData = await this.$store.dispatch("RePolicies/fetchInterviewPolicies", {
          page,
        });
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async myCallback(e) {
      await this.fetchAllInterviewPolicies(e);
    },
  },
  computed: {
    getInterviewPolicies() {
      return this.$store.getters["RePolicies/getInterviewPolicies"];
    },
  },
};
</script>
