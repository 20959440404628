<script setup>
import { defineProps, ref } from "vue";
import { useStore } from "vuex";
import tableOptionsDropdown from "@/components/ui/baseComponents/tableOptionsDropdown.vue";
import baseConfirmationDialog from "@/components/ui/baseComponents/baseConfirmationDialog.vue";
const store = useStore();
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});
const showDropDown = ref(false);
const showDeleteDialog = ref(false);
const deleteAction = () => {
  showDeleteDialog.value = !showDeleteDialog.value;
};
const dropdownOptions = [
  {
    type: "link",
    linkName: "re-view-interview-policy",
    paramName: "interviewPolicyId",
    label: "View",
  },
];
const deleteConfirmation = async () => {
  const { data } = await store.dispatch(
    "RePolicies/deleteInterviewPolicy",
    {
      interview_policy: props.item.id,
    }
  );
  if (data.success) {
    try {
      await store
        .dispatch("RePolicies/fetchInterviewPolicies", {})
        .then(() => {
          deleteAction();
        });
    } catch (error) {
      console.log(error);
    }
  }
};
</script>
<template>
  <td class="border-b cursor-pointer p-3 px-5">
    <tableOptionsDropdown
      :show="showDropDown"
      :id="props.item.id"
      :options="dropdownOptions"
      :dynamicParam="props.item.id"
      @close="showDropDown = !showDropDown"
      @click="showDropDown = !showDropDown"
    >
      <span class="material-icons"> more_horiz </span>
    </tableOptionsDropdown>
    <base-confirmation-dialog :show="showDeleteDialog" title="Are you sure?">
      <base-button @click="deleteConfirmation">Submit</base-button>
      <base-button :mode="'cancel'" @click="deleteAction">Cancel</base-button>
    </base-confirmation-dialog>
  </td>
</template>
